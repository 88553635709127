import {css, html, LitElement} from 'lit';

// Definition einer einfachen Lit-Komponente
class MyElement extends LitElement {

  static styles = css`
        p {
            color: blue;
            font-size: 18px;
            border: 1px solid red;
        }
    `;

  static properties = {
    version: {},
    helloMessage: {type: String}
  }

  constructor() {
    super();
    this.version = 'FIRST'
    this.helloMessage = '';
  }

  async fetchHello() {
    try {
      const response = await fetch('/hello');
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.text();
      this.helloMessage = data;
    } catch (error) {
      console.error('Error fetching /hello:', error);
    }
  }

  // Implementierung der render-Methode, die das HTML-Template zurückgibt
  render() {
    // Verwendung von Lit-Helpers, um HTML zu erstellen
    return html`
            <p>Dies ist eine Lit-Komponente!</p>
            <p>In der ${this.version} version.</p>
            <button @click=${this.fetchHello}>Hello Message abrufen</button>
            <p id="helloMessage">${this.helloMessage}</p>
        `;
  }
}

// Registrierung der Lit-Komponente im globalen Bereich
customElements.define('my-element', MyElement);
