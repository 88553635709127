import { LitElement, html, css } from 'lit';

class LausooFooter extends LitElement {
    static styles = css`
    :host {
      display: block;
      background-color: var(--main-background-color);
      color: var(--main-color);
      text-align: center;
      padding: 20px;
      font-family: var(--main-font-family), sans-serif;
    }

    a {
      color: #61dafb;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  `;

    render() {
        return html`
      <footer>
        <p>❤ 2024 Läuse. Alle Rechte vorbehalten.</p>
        <p>
          <a href="https://example.com/datenschutz">Datenschutz</a> |
          <a href="https://example.com/impressum">Impressum</a> |
          version 0.0.3
        </p>
      </footer>
    `;
    }
}

// Registriere das neue Element mit dem Browser
customElements.define('lausoo-footer', LausooFooter);
