import {LitElement, html, css} from 'lit';
import confetti from 'canvas-confetti';

class LausooTodos extends LitElement {
  static styles = css`
    :host {
      display: block;
      padding: 16px;
      font-family: var(--main-font-family), sans-serif;
    }

    .todo-card {
      background-color: var(--card-background-color);
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 16px;
      margin: 14px 0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }

    .todo-card h3 {
      margin: 0 0 8px;
      padding-top: 8px;
      font-size: 1.5em;
      color: var(--main-color);
    }

    .todo-card.strikethrough h3 {
      text-decoration: line-through;
      color: var(--shy-color);
    }

    .todo-card p {
      margin: 0;
      color: var(--shy-color);
    }

    .todo-button {
      padding: 4px 8px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      font-size: 1em;
      background-color: var(--card-background-color);
      float: right;
      position: relative;
      top: -36px;
    }

    .todo-button:hover {
      transform: scale(1.2);
    }

    .loader {
      border: 16px solid #f3f3f3;
      border-top: 16px solid #333;
      border-radius: 50%;
      width: 120px;
      height: 120px;
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  static properties = {
    todos: {type: Array}
  };

  constructor() {
    super();
    this.fetchAllTasks();
    this.todos = [
      {title: 'Erstes To-Do', description: 'Beschreibung des ersten To-Dos.'},
      {title: 'Zweites To-Do', description: 'Beschreibung des zweiten To-Dos.'},
      {title: 'Drittes To-Do', description: 'Beschreibung des dritten To-Dos.'}
    ];
  }

  // https://lit.dev/docs/components/lifecycle/#firstupdated
  // https://open-wc.org/guides/knowledge/lit-element/lifecycle/
  firstUpdated() {
    this.showLoadingSpinner();
  }

  render() {
    return html`
      <div>
        <lausoo-action @action=${this.handleAction}></lausoo-action>

        <div id="lausoo-cards">
        ${this.todos.map(todo => html`
          <div class="todo-card ${todo.completed ? 'strikethrough' : ''}">
            <h3>${todo.title}</h3>
            <p>${todo.description}</p>
            <span>
              <button class="todo-button" @click=${() => this.handleDeleteButtonClick(todo.id)}>❌</button></span>
              <button class="todo-button" @click=${() => this.handleCompleteButtonClick(todo)}>✅</button>
          </div>
        `)}
        </div>
      </div>
      <div class="loader" id="loader"></div>
    `;
  }

  async fetchAllTasks() {
    await fetch("/api/v1/todos")
      .then(response => response.json())
      .then(response => this.setAll(response));
  }

  setAll(todos) {
    this.todos = todos;
    this.removeLoadingSpinner();
  }

  handleDeleteButtonClick(id) {
    console.log("DELETE ID", id);
    this.deleteTodoById(id);
  }

  async deleteTodoById(id) {
    const request = new Request(`/api/v1/todos/${id}`, {
      method: 'DELETE',
    });
    await fetch(request)
        .then(obj => this.handleResponse(obj));
  }

  handleCompleteButtonClick(todo) {
    console.log('COMPLETE ID', todo.id);

    if (!todo.completed) {
      this.throwConfetti();
      this.playVictory();
    }

    this.completeTodo(todo);
  }

  async completeTodo(todo) {
    const request = new Request(`/api/v1/todos/complete/${todo.id}`, {
      method: 'PATCH',
    });
    await fetch(request)
        .then(obj => {
          this.handleResponse(obj);
        });
  }

  playVictory() {
    const audio = new Audio('static/success-fanfare-trumpets.mp3');
    audio.play();
  }

  throwConfetti() {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: {y: 0.5} // Setzt den Ursprung des Konfetti-Effekts etwas weiter unten im Bildschirm
    });
  }

  handleAction(event) {
    const query = event.detail.query;
    console.log(event);
    console.log('Action input:', query);

    event.preventDefault();
    let task = {title: query};

    const request = new Request('/api/v1/todos', {
      method: 'POST',
      body: JSON.stringify(task),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    fetch(request)
      .then(r => r.json().then(data => ({status: r.status, body: data})))
      .then(obj => this.handleResponse(obj));
    event.target.value = "";
  }

  handleResponse(response) {
    if (response.status === 201) {
      this.addToTasks(response.body);
      this.fetchAllTasks();
    } else if (response.status === 204) {
      this.fetchAllTasks();
    } else if (response.status === 200) {
      this.fetchAllTasks();
    } else if (response.status === 404) {
      this.showErrorMessage(`Can not find ${response.url}`)
    } else {
      console.log(response);
      this.showErrorMessage(response.body.status + ": " + response.body.violations[0].message);
    }
  }

  addToTasks(todo) {
    this.todos = [
      todo,
      ...this.todos
    ];
  }

  showErrorMessage(message) {
    alert(message);
  }

  showLoadingSpinner() {
    this.shadowRoot.querySelector('#loader').hidden = false;
    this.shadowRoot.querySelector('#lausoo-cards').hidden = true;
  }

  removeLoadingSpinner() {
    this.shadowRoot.querySelector('#loader').hidden = true;
    this.shadowRoot.querySelector('#lausoo-cards').hidden = false;
  }
}

customElements.define('lausoo-todos', LausooTodos);
