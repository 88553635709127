import {LitElement, html, css} from 'lit';

class LausooAction extends LitElement {
  static styles = css`
    :host {
      display: block;
      padding: 0;
      font-family: var(--main-font-family), sans-serif;
    }

    .action-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .action-input {
      width: 100%;
      height: 28px;
      padding: 16px;
      margin: 14px 0;
      border: 1px solid #ccc;
      border-radius: 8px;
      font-size: 1em;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      color: var(--main-color);
      font-family: var(--main-font-family), sans-serif;
      background-color: var(--card-background-color);
    }

    .action-button {
      margin-left: 8px;
      padding: 8px 12px;
      border: none;
      background-color: #007bff;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1em;
    }

    .action-button:hover {
      background-color: #0056b3;
    }
  `;

  static properties = {
    actionQuery: {type: String}
  };

  constructor() {
    super();
    this.actionQuery = '';
  }

  render() {
    return html`
      <div class="action-container">
        <input
          id="action"
          type="text"
          class="action-input"
          placeholder="Action..."
          .value=${this.actionQuery}
          @input=${this.updateActionQuery}
          @keypress=${this.handleKeypress}
        />
      </div>
    `;
  }

  updateActionQuery(e) {
    this.actionQuery = e.target.value;
  }

  handleKeypress(event) {
    if (event.keyCode === 13) {
      //event.preventDefault();
      //console.log(task);

      const event = new CustomEvent('action', {
        detail: {query: this.actionQuery}
      });
      this.dispatchEvent(event);
      this.clearActionInput();
    }
  }

  clearActionInput() {
    const actionInput = this.shadowRoot.querySelector('#action');
    if (actionInput) {
      actionInput.value = '';
    }
  }
}

customElements.define('lausoo-action', LausooAction);
